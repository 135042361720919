export const introsTitles = [
    {
        text: 'UX Design',
    },
    {
        text: 'Visual Design',
    },
    {
        text: 'Design Technologist',
    },
    {
        text: 'Web Accessibility',
    },
    {
        text: 'UI Development',
    },
];
