export const about = [
    {
        text: `In the 90's I went to Rowan University to study 
        for a Bachelor of fine arts degree majoring in fine and 
        studio arts and jewelry design. I had a successful career as a fine
        artist and jewelry designer for several years
        after university in Philadelphia Pennsylvania. I
        decided to make a huge life shift and move to
        San Diego California in 2001 and for the next 8
        years I lived as a Jewelry designer/Painter and
        night bartender. I moved to Austin Texas in 2009
        to go back to school for a degree in UX Design
        and fell in love with the field.`,
        id: '1',
    },
    {
        text: `I enjoy listening to problems that user's face
        and try to make their lives better by solving
        those problems. I learned how important it was
        for people to be able to have enjoyable
        experiences while using web applications. I
        first try to understand the user's needs before
        I start designing and developing any web
        experience. Then, I look at the clients needs
        and balance the two together to create the best
        experience possible. I enjoy working with people
        and learning their differences. Learning those
        differences directly impacts how successful a
        web application becomes.`,
        id: '2',
    },
    {
        text: `I have a Staffordshire Terrier named Goober who is my shadow.
        I am an avid boulderer and enjoy climbing
        whenever I get an opportunity. I still enjoy
        designing jewelry and painting on the side but
        being a design technologist is my passion.`,
        id: '3',
    },
];
