export const links = [
    {
        text: 'Contact Me',
        href: 'https://form.jotform.com/231024157700140',
    },
    {
        text: 'GitHub',
        href: 'https://github.com/michael-mclaughlin',
    },
];
